<script>
import LogoHyattZiva from './logos/HyattZiva'
import LogoHyattZilara from './logos/HyattZilara'
import LogoTurquoize from './logos/Turquoize'
import LogoHilton from './logos/Hilton'
import LogoSanctuary from './logos/Sanctuary'
import LogoJewel from './logos/Jewel'
import LogoJewelGrande from './logos/JewelGrande'
import LogoWyndham from './logos/Wyndham'
import LogoSandpiper from './logos/Sandpiper'
import LogoSeadust from './logos/Seadust'

import Blocker from './utilities/Blocker'

export default {
  name: 'OurResorts',
  components: {
    Blocker,
    LogoHyattZiva,
    LogoHyattZilara,
    LogoTurquoize,
    LogoHilton,
    LogoSanctuary,
    LogoJewel,
    LogoJewelGrande,
    LogoWyndham,
  },

  inject: ['resorts', 'brands', 'accentColor'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['hide'],

  data() {
    return {
      breakpoint: 'desktop',
      mode: 'destination',
      highlightedBrand: null,
    }
  },
}
</script>

<template>
  <div v-if="breakpoint !== 'mobile' && breakpoint !== 'tablet'" v-cloak>
    <Teleport to="#portal-destination">
      <div v-if="show" class="fixed top-0 left-0 right-0 z-100">
        <Blocker @click="$emit('hide')" />
        <div id="our-resorts-dropdown" class="fixed top-0 left-0 right-0 z-100">
          <div
            class="container py-8 px-4 md:p-12 text-playa-inverse text-center mx-auto"
          >
            <div
              class="fixed right-4 top-4 border-abs-white border-2 w-12 h-12 cursor-pointer flex justify-center items-center rounded-full"
              @click="$emit('hide')"
            >
              <Icon name="heroicons:x-mark-20-solid" class="w-6 h-6" />
            </div>

            <div class="text-4xl font-thin text-playa-inverse mb-8">
              Our Resorts
            </div>

            <div class="flex justify-center space-x-12 mb-8">
              <div class="md:w-1/2 md:text-right">
                <button
                  class="uppercase font-white border-b-2 pb-1"
                  :class="{
                    'border-white/20': mode !== 'brand',
                  }"
                  @click="mode = 'brand'"
                >
                  {{ $t('by-brand') }}
                </button>
              </div>
              <div class="md:w-1/2 md:text-left">
                <button
                  class="uppercase font-white border-b-2 pb-1"
                  :class="{
                    'border-white/20': mode === 'brand',
                  }"
                  @click="mode = 'destination'"
                >
                  {{ $t('by-destination') }}
                </button>
              </div>
            </div>

            <div
              v-if="mode === 'destination'"
              class="flex flex-col md:flex-row justify-center"
            >
              <div class="mx-8 md:w-1/2 text-center md:text-right mb-8">
                <div class="text-2xl font-thin text-playa-inverse mb-4">
                  Dominican Republic
                </div>
                <ul>
                  <template v-for="(resort, key) in resorts" :key="key">
                    <template v-if="resort.country === 'Dominican Republic'">
                      <li class="mb-2">
                        <a
                          class="text-playa-inverse"
                          :href="`${resort.permalink}${resort.url_partial}` "
                        >
                          {{ resort.name }}
                        </a>
                      </li>
                    </template>
                  </template>
                </ul>
                <div class="text-2xl font-thin text-playa-inverse mb-4">
                  Jamaica
                </div>
                <ul class="mb-8">
                  <template v-for="(resort, key) in resorts" :key="key">
                    <template v-if="resort.country === 'Jamaica'">
                      <li class="mb-2">
                        <a
                          class="text-playa-inverse"
                          :href="`${resort.permalink}${resort.url_partial}`"
                        >
                          {{ resort.name }}
                        </a>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
              <div
                class="border-playa-inverse border-r-1 mx-8 text-center md:text-left md:w-1/2 mb-8"
              >
                <div class="text-2xl font-thin text-playa-inverse mb-4">
                  Mexico
                </div>
                <ul>
                  <template v-for="(resort, key) in resorts" :key="key">
                    <template v-if="resort.country === 'Mexico'">
                      <li class="mb-2">
                        <a
                          class="text-playa-inverse"
                          :href="`${resort.permalink}${resort.url_partial}`"
                        >
                          {{ resort.name }}
                        </a>
                      </li>
                    </template>
                  </template>
                </ul>
              </div>
            </div>

            <transition name="fast-fade" mode="out-in">
              <div v-if="mode === 'brand'">
                <div class="w-full relative grid grid-cols-3 max-w-[1000px] mx-auto gap-20">
                  <div
                    v-for="(brand, key) in brands"
                    :key="key"
                    class="text-white cursor-pointer flex justify-center items-center w-full"
                    @click="highlightedBrand = key"
                  >
                    <component
                      :is="brand.logo_component"
                      :fill="false"
                      :w="300"
                      :h="100"
                      class="max-w-[300px] max-h-[100px]"
                    />
                  </div>
                </div>
                <div>
                  <template v-for="(brand, key) in brands" :key="key">
                    <div
                      v-if="highlightedBrand === key"
                      class="grid grid-cols-3 mt-8"
                    >
                      <div
                        v-for="(resort, resortKey) in brand.resorts"
                        :key="resortKey"
                        class="bg-playa-action-soft text-playa-action rounded-sm w-1/3 bg-cover relative"
                        style="width: 300px; height: 300px"
                        :style="
                          `background-image:url(${resort.featured_image})`
                        "
                      >
                        <a
                          class="btn m-4 absolute bottom-0 left-0 right-0 mx-4 my-4"
                          :href="`${resort.permalink}${resort.url_partial}`"
                        >{{ resort.name }}
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>
