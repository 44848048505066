<script setup lang="ts">
import { animate } from 'motion'

defineProps({
  w: {
    type: String,
    default: '200',
  },
  h: {
    type: String,
    default: '200',
  },
})

const svg = ref(null)

onMounted(() => {
  if (svg.value) {
    const element = svg.value as HTMLElement
    const polygons = element.getElementsByTagName('polygon')

    Array.from(polygons).reverse().forEach((element, index) => {
      animate(element, { opacity: [0, 1], translateY: [100, -85] }, {
        duration: 0.6, // Duration of the fade-in animation in seconds
        delay: (index + 1) * 0.6, // Staggered delay, each subsequent element starts later
        easing: 'ease-out', // Easing function to use
      })
    })
  }
})
</script>

<template>
  <svg
    ref="svg"
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
  >
    <g id="swoosh-up-right-blue">
      <polygon
        fill="#D379AB"
        points="161,78.6 193,78.6 165.9,121.8 133.6,121.8"
      />
      <polygon
        fill="#CB629D"
        points="129.3,78.6 161.3,78.6 134.1,121.8 101.9,121.8"
      />
      <polygon
        fill="#C44C8F"
        points="97.6,78.6 129.5,78.6 102.4,121.8 70.2,121.8"
      />
      <polygon
        fill="#BD3681"
        points="65.9,78.6 97.8,78.6 70.7,121.8 38.4,121.8"
      />
      <polygon
        fill="#B62072"
        points="34.1,78.6 66.1,78.6 38.9,121.8 6.7,121.8"
      />
    </g>
  </svg>
</template>
